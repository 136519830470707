<template>
  <div class="mx-auto my-auto text-center" v-if="!loaded_invitation">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <Form
    v-else
    class="form"
    @invalid-submit="onInvalidSubmit"
    @submit="onSubmit"
    :initial-values="formValues"
    :validation-schema="schema"
    v-slot="{ values }"
    autocomplete="off"
  >
    <CropperModal
      :id="id_modal_cropper"
      :imageUrl="
        !invito.photo.includes('default') ? `${blob}${invito.photo}` : ''
      "
      :isOpenModal="isOpenModal"
      @fileRitagliato="fileRitagliato"
      @closeModal="isOpenModal = false"
    />
    <div class="card shadow mb-7">
      <div class="card-body pt-6 pb-0">
        Numero di inviti disponibili:
        <b>{{ invito.nr_invitations_available }}</b>
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
          >
            <li class="nav-item">
              <span
                style="cursor: pointer"
                aria-current="page"
                class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                :class="selected_tab == 'creazione' ? 'active' : ''"
                @click="selected_tab = 'creazione'"
              >
                Creazione inviti
              </span>
            </li>
            <li class="nav-item">
              <span
                style="cursor: pointer"
                aria-current="page"
                class="router-link-exact-active nav-link text-active-primary me-1 fs-6 text-uppercase"
                :class="selected_tab == 'lista' ? 'active' : ''"
                @click="selected_tab = 'lista'"
              >
                Lista inviti
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-show="selected_tab == 'creazione'">
      <template v-if="invito.nr_invitations_available">
        <div class="card pb-3 mt-0 shadow mb-3">
          <div class="card-body">
            <div class="">
              <div class="row">
                <div
                  class="align-items-center mb-2 d-flex justify-content-between"
                >
                  <h1 class="align-items-center fw-bolder my-2 fs-3">
                    DATI DIRIGENTE/OSPITE
                  </h1>
                </div>

                <!-- nuovo -->

                <!-- nuovo -->

                <div class="row pb-4">
                  <div class="ps-0 col-3 text-center">
                    <div
                      class="my-1 cursor-pointer"
                      @click="isOpenModal = true"
                      data-bs-toggle="modal"
                      data-bs-target="#cropperModal1"
                    >
                      <input
                        type="file"
                        class="d-none"
                        name="avatar"
                        accept=".jpg, .jpeg, .png"
                        id="addAvatarInvitatoCons"
                        @change="onInputChange"
                      />
                      <!-- {{ file.file.url }} -->

                      <img
                        v-if="file && file.file && file.file.url"
                        :src="file.file.url"
                        class="rounded-circle width-100 bg-black"
                        alt="..."
                        :style="
                          invito
                            ? invito.photo.includes('default')
                              ? ''
                              : 'border: 2px solid #cbcbcb'
                            : 'border: 2px solid #cbcbcb'
                        "
                        style="width: 4rem; height: 4rem"
                      />

                      <BlankProfile v-else />
                      <!-- <ProfilePictureX /> -->
                    </div>
                  </div>
                  <div class="col-md-3 col-xs-8">
                    <label class="fw-bold text-gray-700 pe-3">Cognome</label>
                    <Field name="surname" class="form-control" />
                    <ErrorMessage v-show="view_errors" name="surname" />
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <label class="fw-bold text-gray-700 pe-3">Nome</label>
                    <Field name="name" class="form-control" />
                    <ErrorMessage v-show="view_errors" name="name" />
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Data nascita</label
                    >

                    <VueDatePicker
                      placeholder="Data di nascita"
                      :max-date="new Date()"
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                      v-model="date_of_birth_var"
                      @update:modelValue="
                        date_of_birth_var = $event
                          ? moment($event).format('YYYY-MM-DD')
                          : null
                      "
                    />
                    <Field
                      name="date_of_birth"
                      type="hidden"
                      v-model="date_of_birth_var"
                      class="form-control"
                    />
                    <ErrorMessage v-show="view_errors" name="date_of_birth" />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-4 col-xs-12">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Numero di telefono</label
                    >
                    <Field name="phone_number" class="form-control" />
                    <ErrorMessage v-show="view_errors" name="phone_number" />
                  </div>
                  <div class="col-md-4">
                    <label class="fw-bold text-gray-700 pe-3">Email </label>
                    <Field name="email" class="form-control" type="email" />
                    <ErrorMessage v-show="view_errors" name="email" />
                  </div>
                  <div class="col-md-4 col-sm-6">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Lingua parlata
                    </label>
                    <Field class="form-select" name="id_language" as="select">
                      <option
                        v-for="language in lookupLanguage"
                        :key="language.id"
                        :value="language.id"
                      >
                        {{ language.label }}
                      </option>
                    </Field>

                    <ErrorMessage v-show="view_errors" name="id_language" />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-700 pe-3"
                      >Ospite a pagamento
                      <Popper arrow class="ms-2">
                        <button type="button">
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </button>
                        <template #content>
                          <div>
                            L'invito sarà contrassegnato automaticamente come "A
                            pagamento" nei seguenti casi:
                            <ul>
                              <li>
                                Dirigente con due sessioni con o senza
                                accompagnatori
                              </li>
                              <li>
                                Dirigente con una sessione con accompagnatore
                              </li>
                            </ul>
                          </div>
                        </template>
                      </Popper>
                    </label>
                    <br />
                    <!-- :disabled="values.binding_invitation" -->
                    <Field
                      name="payment_invitation"
                      type="checkbox"
                      id="payment_invitation"
                      :value="true"
                      @change="payment_invitation_var = $event.target.checked"
                      :checked="
                        (values.binding_invitation &&
                          sezioni_selezionate > 1 &&
                          !payment_invitation_var) ||
                        (values.binding_invitation &&
                          includes_guests &&
                          !payment_invitation_var)
                      "
                      :disabled="values.binding_invitation"
                    />
                    <Field
                      name="max_sessions"
                      type="hidden"
                      id="max_sessions"
                      :value="listLength"
                    />

                    <ErrorMessage
                      v-show="view_errors"
                      name="payment_invitation"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-700"
                      >Invito limitato a due sessioni (dirigenti)
                      <Popper arrow class="">
                        <button type="button">
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </button>
                        <template #content>
                          <div>
                            Selezionando questa opzione l’invito verrà limitato
                            a massimo due sessioni con invito gratuito solo con
                            selezione di una sessione senza accompagnatori.
                          </div>
                        </template>
                      </Popper>
                    </label>
                    <br />
                    <Field
                      name="binding_invitation"
                      type="checkbox"
                      id="binding_invitation"
                      :value="true"
                      @click="richiestaResetElementi($event, values)"
                    />

                    <ErrorMessage v-show="view_errors" name="payment_guest" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1
          v-if="!photoInserted"
          class="w-100 mb-0 badge text-uppercase badge-light-danger"
        >
          <p class="w-100 text-wrap pt-2">
            Attenzione! Non sono state inserite una o più fotografie. Verifichi
            i campi inseriti. È possibile inserire le fotografie in un secondo
            momento, ma l’invito non sarà perfezionato fino a quando non sarà
            completato in ogni sua parte.
          </p>
        </h1>

        <div class="mt-2 repeater-form">
          <AccordionAccompagnatori
            v-if="!id_session"
            :accompagnatori="guests"
            @GetAcc="get_all_data"
            @setpayment_guest="values.payment_guest = $event"
            @accompagnatoreInserito="guests = $event"
            :payment_guest="values.payment_guest"
            :from_add_person_to_session="true"
            :max_acc_reached="
              binding_invitation
                ? guests.length === MAX_ACCOMPAGNATORI_DIRIGENTI
                : guests.length === invito.max_guest_sessions_invitation_send
            "
            :max_guest_sessions_invitation_send="
              binding_invitation
                ? MAX_ACCOMPAGNATORI_DIRIGENTI
                : invito.max_guest_sessions_invitation_send
            "
            :disabled_checkbox="values.binding_invitation"
          />
        </div>

        <div class="card pb-3 mt-0 shadow mb-3">
          <div class="card-body">
            <div class="">
              <div class="row">
                <div
                  class="align-items-center mb-2 d-flex justify-content-between"
                >
                  <h1 class="align-items-center fw-bolder my-2 fs-3">
                    ASSEGNA SESSIONI
                  </h1>
                </div>
                <div>
                  Numero di sessioni disponibili:<b
                    >&nbsp;{{
                      binding_invitation
                        ? MAX_SESSIONI_DIRIGENTI
                        : invito.max_session_invitation_send
                    }}</b
                  >
                </div>

                <div class="row">
                  <!-- {{ selected_sessions }} -->
                  <SessionsList_copy
                    :key="rerender"
                    :from_invitations="false"
                    :from_invitations_consigliere="true"
                    :id_year_from_invitation="id_year"
                    :id_event_from_invitation="id_event"
                    :checked_list_from_invitation="selected_sessions"
                    :disable_semifinals_finals="values.id_type == 2"
                    :is_ospite="values.id_type == 4 || values.id_type == 3"
                    :selected_sessions_length="selected_sessions.length"
                    :max_sessions_dirigente_ospite="values.max_sessions"
                    @checkedElementSelezionato="set_sessions($event, 'active')"
                    @checkedElementAccompagnatore="
                      set_sessions($event, 'guest')
                    "
                    @listLength="listLength = $event"
                    :accompagnatori="guests"
                    :max_session_invitation_send_reached="
                      binding_invitation
                        ? sezioni_selezionate == MAX_SESSIONI_DIRIGENTI
                        : sezioni_selezionate ==
                          invito.max_session_invitation_send
                    "
                  />
                  <Field
                    type="hidden"
                    name="sessions_length"
                    v-model="listLength"
                  />
                  <Field
                    type="hidden"
                    name="sessions"
                    v-model="selected_sessions"
                  />
                  <ErrorMessage v-show="view_errors" name="sessions" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1
          v-if="!photoInserted"
          class="w-100 mb-0 badge text-uppercase badge-light-danger"
        >
          <p class="w-100 text-wrap pt-2">
            Attenzione! Non sono state inserite una o più fotografie. Verifichi
            i campi inseriti. È possibile inserire le fotografie in un secondo
            momento, ma l’invito non sarà perfezionato fino a quando non sarà
            completato in ogni sua parte.
          </p>
        </h1>

        <div class="modal-footer pt-4">
          <button
            type="submit"
            class="badge bg-light-success text-success rounded blueFit fs-6"
          >
            Salva ed invia
          </button>
        </div>
      </template>
      <template v-else>
        <div class="card pb-3 mt-0 shadow mb-3">
          <div class="card-body">
            <span class="text-gray-700 ps-3 fs-2 mx-auto">
              Nessun invito disponibile.
            </span>
          </div>
        </div>
      </template>
    </div>
    <ListaInvitiConsigliere
      v-show="selected_tab != 'creazione'"
      :id_person="invito.id_person"
      :id_year="invito.id_year"
      :id_event="invito.id_event"
      :id_user="guid_person"
    />
  </Form>
</template>

<script>
import { ref, watch, onMounted, computed } from "vue";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import moment from "moment";
import { format } from "@/functions/formatDate";

import { alertFailed, alertSuccess, alertComposto } from "@/functions/swAlert";
import { API } from "@/enums/costanti";
import { useApiCall } from "@/composables/apiCall";
import { formatDateITA } from "@/functions/formatDate.js";

import SessionsList_copy from "@/views/SessionsList_copy.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { requestAppPost } from "@/db/dbManag";
import { useRoute } from "vue-router";
import AccordionAccompagnatori from "@/components/invitations/AccordionAccompagnatori.vue";

import BlankProfile from "@/components/invitations/SVG/BlankProfile.vue";
import useFileList from "@/composables/file-list";
import { fileSender } from "@/db/dbManag";

import ListaInvitiConsigliere from "@/components/invitations/ListaInvitiConsigliere.vue";
import Popper from "vue3-popper";
import { alertChoiceNoFunction } from "@/functions/swAlert.js";
import CropperModal from "@/components/user-sessions/CropperModal.vue";

export default {
  name: "add-invitation-modal",
  components: {
    Field,
    ErrorMessage,
    Form,
    SessionsList_copy,
    VueDatePicker,
    ListaInvitiConsigliere,
    AccordionAccompagnatori,
    BlankProfile,
    Popper,
    CropperModal,
  },
  emits: ["getList"],
  setup(props, { emit }) {
    const view_errors = ref(false);
    const disableButton = ref(false);
    const onInvalidSubmit = (v) => {
      view_errors.value = true;

      alertFailed("Inserire i campi obbligatori");
    };
    // CREA LA PERSONA
    // LA INVITA ALLE SESSIONI
    // SE E' PAGANTE (DIRIGENTE O OSPITE PAGANTE), FINISCE QUI. VA IN STATO 2 E L'INVITATO DEVE CONTROLLARE L'INVITO AUTONOMAMEMTE
    // ALTRIMENTI (OSPITE NON PAGANTE), DEVE INSERIRE L'INVITO IN STATO 4 E POI AGGIUNGERE L'ACCOMPAGNATORE
    const onSubmit = async (values, { resetForm }) => {
      //   values.people = JSON.stringify(selected_people.value);
      if (props.id_session) {
        // values.sessions = [{ id_session: props.id_session, selected: true }];
        values.sessions = [];
        values.max_sessions = 1;
        props.sessioni_all.forEach((sessione) => {
          selected_sessions.value.push({
            id_session: sessione.id,
            selected: props.id_session == sessione.id ? true : false,
          });
          values.sessions.push({
            id_session: sessione.id,
            selected: props.id_session == sessione.id ? true : false,
          });
        });
        selected_sessions_str.value = JSON.stringify(values.sessions);
      } else {
        values.sessions.forEach((element) => {
          if (values.id_type == 2 || values.id_type == 3) {
            element.selected = true;
          } else {
            element.false = true;
          }
        });
      }

      values.sessions_array = values.sessions;
      values.sessions = JSON.stringify(values.sessions);
      //   category: 1 dirigente 2 ospite
      if (values.id_type != 3 && values.self_payment) {
        values.self_payment = false;
      }
      if (!values.payment_invitation && !values.email) {
        values.email = `ospite_${values.name.trim()}_${values.surname.trim()}_${values.date_of_birth.trim()}@fake.it`;
      }

      const add_person_new = async () => {
        console.log(values);
        if (!values.payment_invitation && !values.email) {
          values.email = `ospite_${values.name}_${values.surname}_${values.date_of_birth}@fake.it`;
        }
        if (
          values.binding_invitation &&
          !values.payment_invitation &&
          getNumberSectionsSelected(selected_sessions_str.value)
            .number_selected_sessions == 1 &&
          !getNumberSectionsSelected(selected_sessions_str.value)
            .includes_guests
        ) {
          values.payment_invitation = false;
        }
        if (
          values.binding_invitation &&
          !values.payment_guest &&
          getNumberSectionsSelected(selected_sessions_str.value)
            .number_selected_sessions == 1 &&
          !getNumberSectionsSelected(selected_sessions_str.value)
            .includes_guests
        ) {
          values.payment_guest = false;
        }
        if (
          values.binding_invitation &&
          !values.payment_invitation &&
          (getNumberSectionsSelected(selected_sessions_str.value)
            .number_selected_sessions > 1 ||
            getNumberSectionsSelected(selected_sessions_str.value)
              .includes_guests)
        ) {
          values.payment_invitation = true;
        }

        if (
          values.binding_invitation &&
          !values.payment_guest &&
          getNumberSectionsSelected(selected_sessions_str.value).includes_guests
        ) {
          values.payment_guest = true;
        }
        // getNumberSectionsSelected(selected_sessions_str.value)
        // .number_selected_sessions > 1 ||
        return requestAppPost(API.INVITATION_ADD_CONFIRM, {
          id_seat: props.selected_seat ? props.selected_seat.id_seat : 0,
          id_person: values.id_person,
          name: values.id_person ? undefined : values.name,
          surname: values.id_person ? undefined : values.surname,
          date_of_birth: values.id_person ? undefined : values.date_of_birth,
          phone_number: values.id_person ? undefined : values.phone_number,
          email: values.id_person ? undefined : values.email,
          id_language: values.id_person ? undefined : values.id_language,
          id_group: values.id_person ? undefined : values.id_group,
          guest: JSON.stringify(guests.value),
          id_event: invito.value.id_event,
          id_year: invito.value.id_year,
          payment_invitation: values.payment_invitation
            ? values.payment_invitation
            : false,
          payment_guest: !guests.value.length
            ? null
            : values.payment_guest
            ? values.payment_guest
            : false,
          sessions: selected_sessions_str.value,
          id_cf_person: invito.value.id_person,
          id_user: guid_person,
          binding_invitation: values.binding_invitation
            ? values.binding_invitation
            : false,
        }).then(({ data: dataSeatAdd, status: reqStatus, statusText }) => {
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 &&
              dataSeatAdd.results &&
              dataSeatAdd.results[0].error)
          ) {
            dataSeatAdd.results &&
            dataSeatAdd.results[0] &&
            dataSeatAdd.results[0].error
              ? alertComposto(dataSeatAdd.results)
              : alertFailed(
                  dataSeatAdd.message
                    ? dataSeatAdd.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
            return false;
          } else {
            return {
              data: dataSeatAdd,
              reqStatus,
              statusText,
            };
          }
        });
      };
      return Swal.fire({
        html: `Sei sicuro di voler invitare ${
          values.id_type == 2
            ? `il dirigente <b>${values.surname} ${values.name}</b>`
            : `l'ospite <b>${values.surname} ${values.name}</b>`
        }? <br> ${
          !photoInserted.value
            ? `<h1 class="w-100 mb-0 badge text-uppercase badge-light-danger">
            <p class="w-100 text-wrap pt-2">
              Attenzione: Inserire foto!
            </p>
          </h1>`
            : ""
        }`,
        confirmButtonText: "Conferma",
        showCancelButton: true,
        icon: "warning",
        cancelButtonText: "Annulla",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
          cancelButton: "btn fw-bold btn-light-danger",
        },
        preConfirm: async () => {
          const returnObj = await add_person_new();
          if (file.value.file) {
            await caricaFoto(
              returnObj.data.results[0].item_id_person,
              returnObj.data.results[0].id_invitation,
              null
            );
          }
          if (
            returnObj.data.results[0].item_id_guest &&
            returnObj.data.results[0].item_id_guest.length
          ) {
            returnObj.data.results[0].item_id_guest.forEach(
              async (guest_res) => {
                guests.value.forEach(async (guest_payload) => {
                  if (
                    guest_payload.id == guest_res.id &&
                    guest_payload.fileToSend
                  ) {
                    await caricaFoto(
                      guest_res.item_id,
                      returnObj.data.results[0].id_invitation,
                      guest_payload.fileToSend
                    );
                  }
                });
              }
            );
          }

          if (file.value.file) {
            file.value = ref({});
          }
          return returnObj;
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data, reqStatus } = result.value;
          if (
            (reqStatus != 200 && reqStatus != 204) ||
            (reqStatus == 200 &&
              data.results &&
              data.results[0] &&
              data.results[0].error)
          ) {
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            return false;
          } else {
            alertSuccess(
              props.id_session
                ? "Posto assegnato con successo"
                : "Persona invitata con successo"
            );

            resetForm();
            selected_sessions_str.value = "";
            selected_sessions.value = [];
            date_of_birth_var.value = null;
            guests.value = [];
            get_invitation();

            return data;
          }
        } else {
          return false;
        }
      });
    };

    const schema = yup.object().shape({
      name: yup.string().required("Obbligatorio"),
      surname: yup.string().required("Obbligatorio"),
      date_of_birth: yup.string().required("Obbligatorio"),
      phone_number: yup.string().nullable(),
      payment_invitation: yup.boolean(),
      binding_invitation: yup.boolean(),
      email: yup
        .string()
        .email("Inserire una email valida")
        .when(["payment_invitation"], {
          is: (payment_invitation) => {
            return payment_invitation;
          },
          then: (schema) => schema.required("Obbligatorio"),
        })
        .test(
          "Controlli invito dirigente",
          "Obbligatorio",
          (value, context) => {
            const { binding_invitation: binding_invitation_schema } =
              context.parent;
            console.log(
              "binding_invitation_schema",
              binding_invitation_schema,
              includes_guests.value,
              sezioni_selezionate.value
            );
            // if (binding_invitation && (includes_guests.value || sezioni_selezionate.value > 1))
            if (
              !value &&
              binding_invitation_schema &&
              (includes_guests.value || sezioni_selezionate.value > 1)
            ) {
              return false;
            } else {
              return true;
            }
          }
        ),
      id_group: yup.number(),
      id_language: yup.number().nullable(),
      self_payment: yup.boolean(),
    });

    const date_var = ref(null);
    const date_var_acc = ref(null);

    const { element: lookups, getElement: get_lookups } = useApiCall(
      API.LOOKUP_GET,
      { keys: "ee|yy|gg|ll|pt" }
    );

    get_lookups();

    const formValues = ref({
      max_sessions: 2,
    });

    const selected_sessions = ref([]);
    const selected_sessions_str = ref("");

    const set_sessions = (values, checkboxClicked) => {
      console.log("set_sessions", values);
      selected_sessions.value = [];
      values.forEach((el) => {
        const objtoPush = {};

        objtoPush.id_session = el.id;
        objtoPush.selected = el.selezionato ? el.selezionato : false;
        const guestsWithId = el.guest
          ? el.guest.map((guest, index) => {
              return { id_guest: guest };
            })
          : [];

        objtoPush.guest = guestsWithId;
        if (objtoPush.id_session)
          selected_sessions.value.push({ ...objtoPush });
      });
      selected_sessions_str.value = JSON.stringify(selected_sessions.value);
    };

    const selected_people = ref([]);
    const selected_people_str = ref([]);

    const people = ref([]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("CREAZIONE INVITI", []);
    });

    const route = useRoute();

    const guid_invitation = route.query.i;
    const guid_person = route.query.p;

    const payloadGuid = ref({
      guid_invitation: guid_invitation,
      guid_person: guid_person,
    });

    const {
      element: sessionResults,
      loaded: loaded_invitation,
      getElement: get_invitation,
      status: status_invitation,
    } = useApiCall(API.INVITATIONS_PUBLIC_VIEW, payloadGuid);

    get_invitation();

    const invito = computed(() =>
      sessionResults.value && sessionResults.value.results[0]
        ? sessionResults.value.results[0]
        : null
    );

    // watch(sessionResults, () => {
    //   setInvitationStatus(sessionResults.value.results[0].status, []);
    // });

    // watch(loaded_invitation, () => {
    //   if (loaded_invitation) {
    //     date_of_birth_var.value = moment(invito.value.date_of_birth).format(
    //       "YYYY-MM-DD"
    //     );
    //   }
    // });

    const date_of_birth_var = ref(null);

    const rerender = ref(0);

    const listLength = ref(0);

    const selected_tab = ref("creazione");

    const guests = ref([]);

    //file
    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(
        e.target.files,
        ".jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .svg"
      );
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const file_controllato = ref(false);
    const errori_txt = ref("");

    const caricaFoto = async (
      item_id,
      id_invitation_crated = null,
      differentFile = null
    ) => {
      if (!file.value.file) {
        alertFailed(
          "Selezionare un gruppo e caricare un file per procedere con il controllo"
        );
        return;
      }
      file_controllato.value = false;
      errori_txt.value = "";
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append(
        "file",
        differentFile ? differentFile.file : file.value.file
      );
      bodyFormData.value.append("item_id", item_id);
      bodyFormData.value.append(
        "id_invitation",
        id_invitation_crated ? id_invitation_crated : props.invito.id
      );
      bodyFormData.value.append("id_event", invito.value.id_event);
      bodyFormData.value.append("id_user", guid_person);

      await fileSender(API.ANAGRAPHICS_PHOTO_EDIT, bodyFormData.value).then(
        (res) => {
          file_controllato.value = true;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            errori_txt.value =
              data && data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi";
          } else {
            file.value.status = true;
            // QUI DA VEDERE COME SARA LA STRUTTURA DEL JSON
            errori_txt.value = data && data.errori ? data.errori : "";

            emit("Refresh");
          }
        }
      );
    };

    const clickImportFile = () => {
      document.getElementById(`addAvatarInvitatoCons`).click();
    };

    const MAX_SESSIONI_DIRIGENTI = 2;
    const MAX_ACCOMPAGNATORI_DIRIGENTI = 1;
    const binding_invitation = ref(false);

    const sezioni_selezionate = computed(() => {
      return selected_sessions.value.filter((item) => item.selected === true)
        .length;
    });
    const includes_guests = computed(() => {
      return selected_sessions.value.some(
        (item) => Array.isArray(item.guest) && item.guest.length > 0
      );
    });

    watch(sezioni_selezionate, (val) => {
      if (binding_invitation.value && !payment_invitation_var.value) {
        if (val > 1) {
          document.getElementById("payment_invitation").checked = true;
        } else {
          if (!includes_guests.value)
            document.getElementById("payment_invitation").checked = false;
        }
      }
    });
    watch(includes_guests, (val) => {
      console.log("includes_guest", val);
      if (binding_invitation.value && !payment_invitation_var.value) {
        if (val) {
          document.getElementById("payment_invitation").checked = true;
        } else {
          document.getElementById("payment_invitation").checked = false;
        }
      }
    });

    const getNumberSectionsSelected = (sessions) => {
      const sessions_real = JSON.parse(sessions);
      const number_selected_sessions = sessions_real.filter(
        (item) => item.selected
      ).length;
      const includes_guests = sessions_real.some(
        (item) => Array.isArray(item.guest) && item.guest.length > 0
      );
      console.log(sessions_real, number_selected_sessions, includes_guests);
      return {
        number_selected_sessions,
        includes_guests,
      };
    };

    const richiestaResetElementi = (event, values) => {
      values.binding_invitation = !event.target.checked;
      alertChoiceNoFunction(
        "Continuando gli accompagnatori e le sessioni selezionate, verranno resettate. <br> Sei sicuro di voler continuare?"
      ).then((success) => {
        if (success) {
          values.binding_invitation = event.target.checked;
          values.payment_guest = false;
          values.payment_invitation = false;
          binding_invitation.value = event.target.checked;
          selected_sessions.value = [];
          rerender.value++;
          guests.value = [];
        } else {
          values.binding_invitation = !event.target.checked;
        }
      });
    };

    const payment_invitation_var = ref(false);

    const id_modal_cropper = ref(1);

    const fileRitagliato = (fileRitagliato) => {
      console.log("fileRitagliato", fileRitagliato);
      file.value.file = fileRitagliato;
      const imageUrl = URL.createObjectURL(fileRitagliato);
      file.value.file.url = imageUrl;
    };
    const isOpenModal = ref(false);

    const photoInserted = computed(() => {
      console.log(
        "photoInserted",
        guests,
        guests.value && guests.value.length,
        file.value
      );
      if (guests.value && guests.value.length) {
        return (
          file.value &&
          file.value.file &&
          file.value.file.url &&
          guests.value.every((accompagnatore) => accompagnatore.photo)
        );
      } else {
        return file.value && file.value.file && file.value.file.url;
      }
    });

    return {
      photoInserted,
      id_modal_cropper,
      fileRitagliato,
      isOpenModal,
      payment_invitation_var,
      richiestaResetElementi,
      sezioni_selezionate,
      includes_guests,
      binding_invitation,
      MAX_SESSIONI_DIRIGENTI,
      MAX_ACCOMPAGNATORI_DIRIGENTI,
      clickImportFile,
      guests,
      selected_tab,
      listLength,
      rerender,
      loaded_invitation,
      date_of_birth_var,
      people,

      invito,

      selected_sessions_str,
      selected_people,
      set_sessions,
      selected_sessions,
      formValues,
      lookups,
      moment,
      format,
      date_var,
      date_var_acc,
      schema,
      onSubmit,
      onInvalidSubmit,
      disableButton,
      API,
      formatDateITA,
      view_errors,
      guid_person,
      alertFailed,
      onInputChange,
      file,
    };
  },
  computed: {
    // sezioni_selezionate() {
    //   return this.selected_sessions.filter((item) => item.selected === true)
    //     .length;
    // },
    // includes_guests() {
    //   return this.selected_sessions.some(
    //     (item) => Array.isArray(item.guest) && item.guest.length > 0
    //   ).length;
    // },
    lookupYears() {
      return this.lookups ? this.lookups.result[1].years : [];
    },
    lookupEvents() {
      return this.lookups ? this.lookups.result[0].events : [];
    },
    lookupSessionType() {
      return this.sessionTypeResults ? this.sessionTypeResults.results : [];
    },
    lookupLanguage() {
      return this.lookups ? this.lookups.result[3].languages : [];
    },
    lookupPersonType() {
      return this.lookups ? this.lookups.result[4].person_type : [];
    },
    lookupGroups() {
      return this.lookups && this.lookups.result && this.lookups.result[2]
        ? this.lookups.result[2].groups
        : [];
    },
  },
};
</script>

<style scoped>
.modal-content {
  width: 150%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(
    .border-active
  ):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
ErrorMessage {
  color: red;
}

:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
